<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Earlier, using hte molecular formula and the UN/DBE, you came up with a list of functional
        groups that could be present in compound A. Now, using the IR data, you should be able to
        narrow down this list.
      </p>
      <p class="mb-2">Check all the functional groups that could be present in compound A.</p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2_M1Q4',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'alcohol', value: 'alcohol'},
        {text: 'ether', value: 'ether'},
        {text: 'ketone', value: 'ketone'},
        {text: 'carboxylic acid', value: 'carboxylicAcid'},
        {text: 'benzene ring', value: 'benzeneRing'},
        {text: 'alkylne', value: 'alkylne'},
        {text: 'amine', value: 'amine'},
        {text: 'aldehyde', value: 'aldehyde'},
        {text: 'ester', value: 'ester'},
        {text: 'nitrile', value: 'nitrile'},
        {text: 'alkene', value: 'alkene'},
        {text: 'ring', value: 'ring'},
        {text: 'epoxide', value: 'epoxide'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
